<template>
  <Layout>
    <report-tab type="posts"/>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/vertical.vue";
import ReportTab from "@/components/moderation/report-tab";
export default {
  components: {
    Layout,
    ReportTab,
  },
  data() {
    return {
    };
  },
};
</script>
